<template>
  <b-overlay variant="transparent" :opacity="1" :show="isPageLoading" spinner-variant="primary">
    <b-row>
      <b-col sm="12">
        <form-wizard :title="null" :subtitle="null" shape="square" color="#7367F0" finish-button-text="Selesai" back-button-text="Kembali">
          <tab-content title="Informasi Item" :before-change="submitStepOne">
            <validation-observer ref="stepOne" tag="form">
              <b-row>
                <b-col sm="12" md="8">
                  <form-select-nested ref="categorySelect" @value-changed="categoryChange" url="v1/item_category_select2" :rules="{required: true}" v-model="stepOneData.id_category" label="Kategori Item"></form-select-nested>
                </b-col>
                <b-col sm="12" md="8">
                  <form-input :rules="{required: true}" v-model="stepOneData.code" label="Kode"></form-input>
                </b-col>
                <b-col sm="12" md="8">
                  <form-input :rules="{required: true}" v-model="stepOneData.name" label="Nama Item"></form-input>
                </b-col>
                <b-col sm="12" md="8">
                  <form-input :rules="{required: false}" v-model="stepOneData.alias" label="Nama Alias"></form-input>
                </b-col>
                <b-col sm="12" md="8">
                  <form-input :rules="{required: false}" v-model="stepOneData.barcode" label="Barcode"></form-input>
                </b-col>
                <b-col sm="12" md="8">
                  <form-input-number :rules="{required: true}" v-model="stepOneData.min_stock" label="Min Stok"></form-input-number>
                </b-col>
                <b-col sm="12" md="8">
                  <form-select ref="supplierSelect" @value-changed="supplierChange" url="v1/supplier_select2" :rules="{required: false}" v-model="stepOneData.id_supplier" label="Pemasok Default"></form-select>
                </b-col>
                <b-col sm="12" md="8">
                  <form-input :rules="{required: false}" v-model="stepOneData.note" label="Keterangan"></form-input>
                </b-col>
                <b-col sm="12" md="8">
                  <form-checkbox v-model="stepOneData.is_active" label="Aktif"></form-checkbox>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content title="Satuan dan Harga" :before-change="submitStep2">
            <b-row v-if="stepTwoData.details.length==0">
              <b-col sm="12" md="3">
                <b-button variant="primary" @click="addNewUnit"><feather-icon icon="PlusIcon"></feather-icon> Tambah Satuan</b-button>
              </b-col>
            </b-row>
            <validation-observer ref="stepTwo" tag="form">
              <b-row v-for="(tr,i) in stepTwoData.details" :key="i">
                <b-col sm="12" md="3">
                  <form-select :ref="`unitSelectRow${i}`" @value-changed="rowUnitSelect($event,i)" url="v1/unit_select2" :rules="{ required: true }" :label="`Satuan ke ${i+1}`" v-model="tr.id_unit"></form-select>
                </b-col>
                <b-col sm="12" md="3">
                  <form-input-number :disabled="i==0" input-group :append="stepTwoData.details[0].name_unit" :rules="{ required: true }" :label="`Qty ke ${i+1}`" v-model="tr.volume_of" @input="tr.purchase_price = parseFloat(tr.volume_of) * stepTwoData.details[0].purchase_price"></form-input-number>
                </b-col>
                <b-col sm="12" md="3">
                  <form-input-number input-group :append="stepTwoData.details[0].name_unit" :rules="{ required: true }" :label="`Harga ke ${i+1} (Rp)`" v-model="tr.purchase_price"></form-input-number>
                </b-col>
                <b-col sm="12" md="1" class="mt-2">
                  <b-button-group size="sm">
                    <b-button v-if="i>0" variant="danger" @click="deleteUnit(i)">
                      <feather-icon icon="TrashIcon"></feather-icon>
                    </b-button>
                    <b-button v-if="stepTwoData.details.length==i+1" variant="primary" @click="addNewUnit">
                      <feather-icon icon="PlusIcon"></feather-icon>
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col sm="6">
                  <form-select-static :options="unitOptionRow" ref="unitPurchaseSelect" @value-changed="stepTwoData.name_unit_purchase = $event.label" :rules="{ required: true }" label="Satuan Pembelian" v-model="stepTwoData.id_unit_purchase"></form-select-static>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <form-select-static :options="unitOptionRow" ref="unitSaleSelect" @value-changed="stepTwoData.name_unit_sale = $event.label" :rules="{ required: true }" label="Satuan Penjualan" v-model="stepTwoData.id_unit_sale"></form-select-static>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
          <tab-content title="Harga Per Cabang dan Mitra" :before-change="submitStep3">
            <validation-observer ref="stepThree" tag="form">
              <b-row>
                <b-col v-for="(tr,i) in salePricePaginateData.data" :key="i" sm="12" md="6">
                  <b-card :title="tr.label">
                    <b-row>
                      <b-col sm="12" v-for="v in tr.details" :key="v.uniqid">
                        <form-input-number :rules="{minimal: [0]}" :value="v.price" @input="$store.commit('utils/UPDATE_VALUE',{uniqid: v.uniqid, value: $event})" :label="'Harga '+v.name_payment_partner"></form-input-number>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <b-col sm="12" class="text-center">
                  <b-pagination :value="salePricePage" :per-page="salePricePaginateData.perPage" :total-rows="salePricePaginateData.total" @input="$store.commit('utils/CHANGE_PAGE',$event)"></b-pagination>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import FormInput from '@/my-components/FormInput.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormCheckbox from '@/my-components/FormCheckbox.vue'
import FormSelect from '@/my-components/FormSelect.vue'
import FormSelectNested from '@/my-components/FormSelectNested.vue'
import FormSelectStatic from '@/my-components/FormSelectStatic.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapActions, mapGetters, mapState } from 'vuex'
import utils from './utils'
import {BButtonGroup,BButton,BOverlay,BCard,BPagination} from 'bootstrap-vue'
import _ from 'lodash'

export default {
  components:{
    FormWizard,
    TabContent,
    ValidationObserver,
    FormInput,
    FormSelect,
    FormSelectNested,
    BButtonGroup,
    BButton,
    BOverlay,
    BCard,
    BPagination,
    FormCheckbox,
    FormSelectStatic,
    FormInputNumber
  },
  data(){
    return {
      stepOneData:{
        id: this.$route.params.id,
        id_category: null,
        name_category: "",
        code: "",
        name: "",
        alias: "",
        barcode: "",
        min_stock: 0,
        id_supplier: null,
        name_supplier: "",
        note: "",
        is_active: true
      },
      stepTwoData:{
        id_unit_sale: null,
        name_unit_sale: "",
        id_unit_purchase: null,
        name_unit_purchase: "",
        details:[
          Object.assign({},utils.details)
        ]
      },
      stepThreeData:{},
      isButtonLoading: true,
      isPageLoading: false,
    }
  },
  computed:{
    ...mapState({
      units: state => state.units.selectOptions,
      branchList: state => state.companies.lists,
      partnerList: state => state.partners.lists,
      salePriceData: state => state.utils.salePriceArray,
      salePricePage: state => state.utils.salePricePage
    }),
    ...mapGetters({
      salePricePaginateData: 'utils/paginateSalePrice'
    }),
    unitOptionRow(){
      let options = []
      for (let i = 0; i < this.stepTwoData.details.length; i++) {
        const e = this.stepTwoData.details[i];
        if(e.id_unit) options.push({value:e.id_unit,label:e.name_unit})
      }
      return _.uniqBy(options,'value')
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'items/show',
      dispatchShowStepTwo: 'items/showStepTwo',
      dispatchShowStepThree: 'items/showStepThree',
      dispatchSaveStepOne: 'items/store',
      dispatchSaveStepTwo: 'items/storeStepTwo',
      dispatchSaveStepThree: 'items/storeStepThree',
      dispatchUpdateStepOne: 'items/update',
      dispatchBranchOptions: 'companies/getList',
      dispatchPartnerOptions: 'partners/getList'
    }),
    categoryChange(json){
      if(json.label) this.stepOneData.name_category = json.label;
      else this.stepOneData.name_category = '';
    },
    supplierChange(json){
      if(json.label) this.stepOneData.name_supplier = json.label;
      else this.stepOneData.name_supplier = '';
    },
    rowUnitSelect(data,row){
      this.stepTwoData.details[row].name_unit = data.label
      const idunitsale = this.stepTwoData.id_unit_sale
      const idunitpurchase = this.stepTwoData.id_unit_purchase
      if(_.filter(this.unitOptionRow,{'value':idunitsale}).length<1) {
        this.stepTwoData.id_unit_sale=null
        this.stepTwoData.name_unit_sale=""
      }
      if(_.filter(this.unitOptionRow,{'value':idunitpurchase}).length<1) {
        this.stepTwoData.id_unit_purchase=null
        this.stepTwoData.name_unit_purchase=""
      }
    },
    deleteUnit(row){
      this.stepTwoData.details.splice(row, 1)
      this.stepTwoData.id_unit_sale = null
      this.stepTwoData.name_unit_sale = ""
      this.stepTwoData.id_unit_purchase = null
      this.stepTwoData.name_unit_purchase = ""
    },
    addNewUnit(){
      const getPreviousData = this.stepTwoData.details[0]
      let price = 0
      if(getPreviousData) price = parseFloat(getPreviousData.purchase_price)
      this.stepTwoData.details.push( Object.assign({},utils.details,{purchase_price:price}) )
    },
    async getData(){
      this.isPageLoading = true
      Promise.all([
        this.dispatchBranchOptions(),
        this.dispatchPartnerOptions(),
      ])
      .then(() => {
        this.$store.commit('utils/SET_SALE_PRICE_ARRAY',{
          branch: this.branchList,
          partners: this.partnerList
        })
        this.isPageLoading = false
      })
      .catch(error => {
        this.handleError(error)
      })
    },
    async getDataEdit(){
      this.isPageLoading = true
      Promise.all([
        this.dispatchShow(this.stepOneData.id),
        this.dispatchShowStepTwo(this.stepOneData.id),
        this.dispatchBranchOptions(),
        this.dispatchPartnerOptions(),
        this.dispatchShowStepThree(this.stepOneData.id)
      ]).then(result => {
        const data = result[0]
        const s2data = result[1]
        this.stepOneData.id_category = data.id_category
        this.stepOneData.name_category = data.name_category
        this.stepOneData.code = data.code
        this.stepOneData.name = data.name
        this.stepOneData.alias = data.alias
        this.stepOneData.barcode = data.barcode
        this.stepOneData.min_stock = data.min_stock
        this.stepOneData.id_supplier = data.id_supplier
        this.stepOneData.name_supplier = data.name_supplier
        this.stepOneData.note = data.note
        this.stepOneData.is_active = data.is_active ? true : false

        this.stepTwoData.id_unit_sale = data.id_unit_sale
        this.stepTwoData.id_unit_purchase = data.id_unit_purchase
        this.stepTwoData.name_unit_sale = data.name_unit_sale
        this.stepTwoData.name_unit_purchase = data.name_unit_purchase
        let step2details = []
        for (let i = 0; i < s2data.length; i++) {
          const d = s2data[i];
          step2details.push({
            id_item: d.id_item,
            name_item: d.name_item,
            id_unit: d.id_unit,
            name_unit: d.name_unit,
            volume_of: d.volume_of,
            purchase_price: d.purchase_price
          })
        }
        this.stepTwoData.details = step2details
        for (let i = 0; i < step2details.length; i++) {
          const d = step2details[i];
          const refs = `unitSelectRow${i}`
          this.$nextTick(() => {
            this.$refs[refs][0].initOption([{"value":d.id_unit,"label":d.name_unit}])
          })
        }
        if (data.id_supplier) this.$refs.supplierSelect.initOption([{"value":data.id_supplier,"label":data.name_supplier}])
        if (data.id_category) this.$refs.categorySelect.loadData(this.id_category)
        this.isPageLoading = false
        return result
      })
      .then((result) => {
        this.$store.commit('utils/SET_SALE_PRICE_ARRAY',{
          branch: this.branchList,
          partners: this.partnerList,
          item: this.stepOneData
        })
        const s3data = result[4]
        this.$store.commit('utils/SET_NEW_PRICE_ARRAY',s3data)
      })
      .catch((error) => {
        this.handleError(error)
      })
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? (valid ? null : false) : null;
    },
    submitStepOne(){
      return new Promise((resolve, reject) => {
        this.$refs.stepOne.validate().then(async success => {
          this.isPageLoading = true
          if (success) {
            let params = JSON.parse( JSON.stringify(this.stepOneData) )
            params.is_active = this.stepOneData.is_active ? 1 : 0
            let data = {}
            try {
              if(!params.id) data = await this.dispatchSaveStepOne(params)
              else data = await this.dispatchUpdateStepOne(params)
              if(data.data.id_header) {
                this.stepOneData.id = data.data.id_header
              }
              resolve(true)
            } catch (error) {
              this.handleError(error)
              reject(error)
            }
          } else {
            reject()
          }
          this.isPageLoading = false
        })
      })
    },
    async submitStep2(){
      return new Promise((resolve, reject) => {
        this.$refs.stepTwo.validate().then(async success => {
          this.isPageLoading = true
          if (success) {
            let params = JSON.parse( JSON.stringify(this.stepTwoData) )
            for (let i = 0; i < params.details.length; i++) {
              params.details[i].id_item = this.stepOneData.id
              params.details[i].name_item = this.stepOneData.name
            }
            params.id = this.stepOneData.id
            params.code = this.stepOneData.code
            try {
              await this.dispatchSaveStepTwo(params)
              resolve(true)
            } catch (error) {
              this.handleError(error)
              reject(error)
            }
          } else {
            reject()
          }
          this.isPageLoading = false
        })
      })
    },
    async submitStep3(){
      return new Promise((resolve,reject) => {
        this.$refs.stepThree.validate().then(async success => {
          this.isPageLoading = true
          if (success) {
            const reassign = JSON.parse( JSON.stringify(this.salePriceData) )
            let params = _.flatMapDeep(reassign, (n) => {return n.details})
            for (let i = 0; i < params.length; i++) {
              const e = params[i];
              params[i] = Object.assign(e,{id_item:this.stepOneData.id,name_item:this.stepOneData.name})
            }
            try {
              await this.dispatchSaveStepThree({
                data: params,
                id: this.stepOneData.id,
                code: this.stepOneData.name
              })
              this.toastSuccess("OK","Data Berhasil Disimpan")
              this.$router.push({name:'items'})
              resolve(true)
            } catch (error) {
              this.handleError(error)
              reject(error)
            }
          } else {
            reject()
          }
          this.isPageLoading = false
        })
      })
    }
  },
  mounted(){
    if (this.$route.params.id) this.getDataEdit()
    else this.getData()
  },
  destroyed(){
    this.$store.commit('utils/DESTROY_SALE_PRICE_ARRAY')
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>